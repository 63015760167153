@use 'common'
@import 'react-toastify/dist/ReactToastify.css'

:root
	--common-edge-gap: 1rem
	--common-content-width-small: 32rem
	--common-content-width-normal: 55rem
	--common-content-width-medium: 68.75rem
	--common-content-width-wide: 83.5rem
	overflow-y: scroll

	// https://www.npmjs.com/package/shared-loading-indicator
	--ProgressLoadingIndicator-color: #{common.$red}

	@media (min-width: 40rem)
		--common-edge-gap: 2rem

body
	margin: 0
	font-family: common.$inter-font
	background-color: #F8F6EE
	color: #000000

*, *::before, *::after
	box-sizing: border-box

a
	color: inherit
	&:hover
		text-decoration: none
button
	cursor: pointer
